import { defineComponent, ref, computed, watch, reactive } from '@vue/composition-api';
import { isEqual } from 'lodash';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import EventBus from '@/shared/services/eventBus';
import { useAddNewVenueTagsMutation, useGetAllTagsQuery, useGetVenueTagsQuery } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import VenueDetailsForm from '@/pages/vendor/venue/details/forms/VenueDetailsForm';
import { useToast } from 'vue-toastification/composition';
const scrollOffset = {
    name: -140,
    images: -100,
    workingTime: -120,
    description: -200,
    placeID: -110,
    contactName: -110,
    contactPhone: -110
};
export default defineComponent({
    components: {
        SaveFooter
    },
    props: {
        venue: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const toast = useToast();
        const isSaving = ref(false);
        const isInformingVenueUpdated = ref(false);
        const loadedForm = ref({});
        const locale = computed(() => globalThis.$i18n.locale.toLowerCase());
        const selectedTagsIds = ref([]);
        const selectedTagsIdsBackup = ref([]);
        const errorMessageStatus = ref(false);
        const venueTags = computed(() => globalThis.$store.getters['$_vendor/venueTags']);
        const allTagsLocalized = ref([]);
        const venueDetails = computed(() => globalThis.$store.getters['$_vendor/venueDetails']);
        const getVenueTagsQueryVariables = reactive({
            slug: props.venue ? props.venue.slug : ''
        });
        const getVenueTagsQueryOptions = reactive({
            enabled: !!root.$route.params.venueId
        });
        const { refetch: refetchVenueTags, onResult: onVenueTagsResult } = useGetVenueTagsQuery(getVenueTagsQueryVariables, getVenueTagsQueryOptions);
        onVenueTagsResult((result) => {
            selectedTagsIds.value =
                result.data?.specific_instance_by_slug?.venue_tags.map(tag => tag.tag_id) || [];
            selectedTagsIdsBackup.value = [...selectedTagsIds.value];
            setVenueTags();
        });
        watch(() => venueTags.value, () => {
            selectedTagsIds.value = venueTags.value;
        }, { immediate: true });
        const allTagsQueryOptions = reactive({
            enabled: true
        });
        const { onResult } = useGetAllTagsQuery(allTagsQueryOptions);
        onResult((result) => {
            if (result?.loading)
                return;
            allTagsLocalized.value = result.data?.get_all_tags
                .map(tag => {
                return {
                    ...tag,
                    value: tag.tag_id,
                    label: `${tag[locale.value]} (${tag.importance})`
                };
            })
                .sort((a, b) => a[locale.value].localeCompare(b[locale.value], globalThis.$i18n.locale)); // sorted alphabetically
        });
        const { mutate: addNewVenueTagsMutation } = useAddNewVenueTagsMutation();
        const onTagClick = (tag) => {
            if (selectedTagsIds.value.includes(tag.tag_id)) {
                selectedTagsIds.value.splice(selectedTagsIds.value.indexOf(tag.tag_id), 1);
            }
            else {
                selectedTagsIds.value.push(tag.tag_id);
            }
            root.$route.params.venueId
                ? setVenueTags()
                : saveDataToLocalStorage();
            emit('settingsChanged', true);
        };
        const isSettingsChanged = computed(() => {
            const tags = [...selectedTagsIds.value].sort((a, b) => a - b);
            const tagsBackup = [...selectedTagsIdsBackup.value].sort((a, b) => a - b);
            return !isEqual(tags, tagsBackup);
        });
        const hasChanges = () => {
            return root.$route.params.venueId && isSettingsChanged.value;
        };
        const isChangesSaved = () => {
            return !hasChanges() && isInformingVenueUpdated.value;
        };
        const startInformingVenueUpdated = () => {
            isInformingVenueUpdated.value = true;
        };
        const cancelInformingVenueUpdated = () => {
            isInformingVenueUpdated.value = false;
        };
        const onRevert = () => {
            selectedTagsIds.value = [...selectedTagsIdsBackup.value];
            setVenueTags();
        };
        const saveDataToLocalStorage = () => {
            if (!root.$route.params.venueId) {
                globalThis.$store.commit('$_vendor/SET_SELECTED_TAGS', selectedTagsIds.value);
            }
        };
        const setVenueTags = () => {
            globalThis.$store.commit('$_vendor/SET_VENUE_TAGS', selectedTagsIds.value);
        };
        watch(() => selectedTagsIds.value, tags => {
            if (errorMessageStatus.value && tags.length > 2) {
                errorMessageStatus.value = false;
            }
        }, { immediate: true, deep: true });
        const form = ref(new VenueDetailsForm());
        const errors = ref([]);
        const checkFormValid = () => {
            errors.value = [];
            form.value.validate();
            Object.keys(form.value).forEach(key => {
                const err = form.value.errors(key);
                if (err) {
                    errors.value.push(key);
                }
            });
            return errors.value.length === 0;
        };
        const checkErrors = () => {
            if (selectedTagsIds.value.length < 3) {
                errorMessageStatus.value = true;
                return false;
            }
            if (!root.$route.params.venueId) {
                form.value.loadFromModel(venueDetails.value);
                if (!checkFormValid()) {
                    const errorsFormatted = errors?.value.map(error => {
                        return {
                            name: error,
                            offset: scrollOffset[error] || 0
                        };
                    });
                    globalThis.$router.push({
                        name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.MAIN,
                        params: { errors: errorsFormatted }
                    });
                    return false;
                }
                return true;
            }
            return true;
        };
        const createVenueTags = (id) => {
            return addNewVenueTagsMutation({
                tagIds: selectedTagsIds.value,
                venueId: id
            });
        };
        const onSaveChanges = () => {
            if (!checkErrors())
                return;
            createVenueTags(props.venue.id)
                .then(() => {
                refetchVenueTags();
                startInformingVenueUpdated();
            })
                .catch(cancelInformingVenueUpdated);
        };
        const mainAction = async () => {
            if (!checkErrors())
                return;
            isSaving.value = true;
            try {
                const venueResponse = await globalThis.$store.dispatch('$_vendor/createVenue');
                localStorage.setItem('createdVenueId', venueResponse.venue_id);
                createVenueTags(venueResponse.venue_id).then(() => {
                    EventBus.$emit('finishVenueCreation');
                    const name = globalThis.$i18n.locale && globalThis.$i18n.locale !== 'nl'
                        ? `${globalThis.$i18n.locale}-${ROUTE_LIST_NAMES.SUCCESS.VENUE_CREATED}`
                        : ROUTE_LIST_NAMES.SUCCESS.VENUE_CREATED;
                    globalThis.$router.push({
                        name,
                        params: {
                            venueId: venueResponse.venue_id,
                            venueName: venueResponse.venue_name
                        }
                    });
                });
            }
            catch (e) {
                const toastText = root.$i18n.t(`common.errors.${e.status === 500
                    ? 'something_wrong'
                    : 'onboarding.mandatory_fields'}`);
                toast.error(toastText);
            }
            isSaving.value = false;
        };
        return {
            allTagsLocalized,
            errorMessageStatus,
            GeoPagesRoutes,
            isInformingVenueUpdated,
            isSaving,
            loadedForm,
            locale,
            selectedTagsIds,
            venueTags,
            useGetLocalizedPath,
            cancelInformingVenueUpdated,
            checkErrors,
            hasChanges,
            isChangesSaved,
            mainAction,
            onTagClick,
            onRevert,
            onSaveChanges,
            startInformingVenueUpdated
        };
    }
});
