import { render, staticRenderFns } from "./VenueTagsPage.vue?vue&type=template&id=28d4062d&scoped=true"
import script from "./VenueTagsPage.vue?vue&type=script&lang=ts"
export * from "./VenueTagsPage.vue?vue&type=script&lang=ts"
import style0 from "./VenueTagsPage.vue?vue&type=style&index=0&id=28d4062d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d4062d",
  null
  
)

export default component.exports